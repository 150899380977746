/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400&display=swap');

@import "styles.utility";

body {
  padding: 0;
  margin: 0;
  background-color: #FFFF;
}

* {
  font-family: 'Ubuntu', sans-serif;
}
.tsparticles-canvas-el {
  position: relative !important;
  height: 550px !important;
}


h1 {
  font-size: 30px;
  color: #355881;
  font-weight: bold;
}

.container {
  max-width: 1024px !important;

  @media only screen and (min-width: 1024px) {
    max-width: 792px;
    margin: 0 auto;
    padding: 0;
  }
}
